<template>
    <div class="stats-container">
        <div class="blob-container-top">
            <stats-blob>
                <div class="blob-number">{{ billableHours }}</div>
                <div class="blob-text">
                    Billable
                    <br>hours
                </div>
            </stats-blob>
            <stats-blob>
                <div class="blob-number">{{ availableHours }}</div>
                <div class="blob-text">
                    Available
                    <br>hours
                </div>
            </stats-blob>
            <stats-blob>
                <div class="blob-number">{{ budgetTarget }}</div>
                <div class="blob-text">
                    Budget
                    <br>Target
                </div>
            </stats-blob>
        </div>
    </div>
</template>

<script>
import StatsBlob from "./StatsBlob";
import {subscribeAggregatedRecord} from "@/api";
import {getSettingsDoc} from "@/api";

export default {
    props: {
        office: {
            type: String,
            required: true
        },
        isHistoryMode: Boolean,
        taskRecords: {
            type: Array,
            required: true
        }
    },

    components: {
        StatsBlob
    },

    data() {
        return {
            maxBudget: 0,
        };
    },

    computed: {
        budgetTarget() {
            if (this.billableHours !== "" && this.maxBudget !== 0) {
                return Math.floor((this.billableHours / this.maxBudget) * 100) + "%";
            } else return "";
        },

        billableHours() {
            const hours = this.taskRecords.reduce((acc, cur) => {
                return acc + cur.billableDays
            }, 0)

            return Math.floor(7.5 * hours)
        },

        availableHours() {
            const hours = this.taskRecords.reduce((acc, cur) => {
                return acc + cur.availableDays
            }, 0)

            return Math.floor(7.5 * hours)
        }
    },

    methods: {
        subscribeAggregatedValues(cb) {
            let year;
            let weekNumber;
            if (!this.isHistoryMode) {
                year = new Date().getFullYear();
                weekNumber = this.weekNumber;
            } else {
                year = this.$route.params.year;
                weekNumber = this.$route.params.week;
            }
            this.unsubscribe = subscribeAggregatedRecord(
                {
                    type: "hours",
                    office: this.office,
                    year,
                    weekNumber
                },
                cb
            );
        }
    },

    async created() {


        // Get max budget
        let value = await getSettingsDoc({
            docId: `budget-max-${this.office}`
        });
        if (value && value.value !== undefined) {
            this.maxBudget = value.value;
        }
    },

};
</script>

<style>
.stats-container {
    color: #fff;
    font-size: 10px;
    overflow: hidden;
    background-image: linear-gradient(to top left, #161c22, #0c1013);
    box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.5);
}

.blob-container-top {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: center;
}

.blob-number {
    order: 2;
    margin: auto 0;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -2px;
}

.blob-text {
    order: 1;
    margin: auto 16px auto 0;
    font-size: 13px;
    line-height: 1;
    color: #7a8799;
    text-align: right;
    text-transform: uppercase;
}
</style>
