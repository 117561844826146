<template>
  <div class="blob-wrapper">
    <div class="blob-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["number", "text"]
};
</script>

<style>
.blob-wrapper {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 320px;
  height: 100%;
  border-left: 1px solid #1d2127;
  border-right: 1px solid #000;
}
.blob-wrapper:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  bottom: 0;
  width: 1px;
  border-right: 1px solid #000;
}
.blob-wrapper:last-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  width: 1px;
  border-right: 1px solid #1d2127;
}
.blob-content {
  display: flex; 
  justify-content: flex-start;
  align-content: center;
  flex-flow: row nowrap;
  height: 56px;
  margin: 0 auto
}
</style>
