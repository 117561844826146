<template>
  <div class="gecko">
    <iframe
      src="https://share.geckoboard.com/dashboards/D98B6C022750F08E?refresh"
      frameborder="0"
      class="gecko__iframe"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style>
.gecko {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.gecko__iframe {
  width: 100%;
  height: 100%;
}
</style>
