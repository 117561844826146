<template>
  <div class="screen-container">
    <keep-alive>
      <component
        :is="selectedMode"
        :selectedOffice="selectedOffice"
        :isHistoryMode="isHistoryMode"
      />
    </keep-alive>
  </div>
</template>

<script>
import { subscribeToMode } from "@/api";

import Board from "../components/Screen/Board";
import Panic from "./Panic";
import Gecko from "./GeckoBoard";
import BoardCardView from "../components/Screen/BoardCardView.vue";

export default {
  name: "Screen",
  components: {
    Board,
    Panic,
    Gecko
  },
  data() {
    return {
      mode: "standup",
      isHistoryMode: false
    };
  },
  computed: {
    selectedOffice() {
      return this.$route.params.office;
    },
    selectedMode() {
      if (this.mode === "standup") return Board;
      else if (this.mode === "panic") return Panic;
      else if (this.mode === "gecko") return Gecko;
      else if (this.mode === "randomizer") return BoardCardView;
      else return Board;
    }
  },
  created() {
    if (this.$route.params.week && this.$route.params.year)
      this.isHistoryMode = true;
    const office = this.$route.params.office;
    subscribeToMode(office, mode => (this.mode = mode));

    // Set viewport to work with android tv app
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=1920, height=1080");
  },

  beforeDestroy() {
    // Reset view port
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=device-width,initial-scale=1.0");
  }
};
</script>

<style>
.screen-container {
  background-color: #0c1014;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #212121;
}
</style>
