<template>
  <div class="card-view">
    <div class="randomize-main" v-if="currentViewMode === viewModes[0]">
      <div class="name">{{ selectedEmployee.firstName }}</div>
      <div class="hour-info">
        <div class="billable">
          <div>Billable Days</div>
          <div>{{ billableDays }}</div>
        </div>

        <div class="available">
          <div>Available Days</div>
          <div>{{ availableDays }}</div>
        </div>
      </div>
      <div class="working-on">
        <h4>Working on:</h4>
        <div>{{ workingOnText }}</div>
      </div>
      <div v-if="imageUrlWithConstraints !== ''" class="image-container">
        <img
          :src="imageUrlWithConstraints"
          alt="TaskImage"
          class="working-image"
        />
      </div>
    </div>
    <div class="spinner" v-else-if="currentViewMode === viewModes[1]">?</div>
    <div v-else-if="currentViewMode === viewModes[2]">
      <h2>Great job! You're all done!</h2>
    </div>
    <div v-else>
      <h2>Get ready to be randomized...!</h2>
    </div>
  </div>
</template>

<script>
import { getTaskRecord, subscribeToRandomizedEmployee } from "@/api";

export default {
  props: {
    selectedOffice: String
  },

  data() {
    return {
      selectedEmployee: {},
      taskRecord: {},
      isSpinning: false,
      viewModes: ["normal", "spinning", "finished", "notStarted"],
      currentViewMode: "normal",
      catImage: ""
    };
  },

  computed: {
    workingOnText() {
      if (this.taskRecord) return this.taskRecord.workingOn;
      else return "";
    },
    /* NOT IN USE */
    /* imageUrl() {
      if (
        this.taskRecord &&
        this.taskRecord.imageUrl &&
        this.taskRecord.imageUrl !== ""
      )
        return this.taskRecord.imageUrl;
      else if (this.catImage !== "") return this.catImage;
      else return "";
    }, */

    imageUrlWithConstraints() {
      if (
        this.taskRecord &&
        this.taskRecord.imageUrl &&
        this.taskRecord.imageUrl !== ""
      ) {
        let splitUrl = this.taskRecord.imageUrl.split("/upload");
        return `${splitUrl[0]}/upload/q_80,w_800/${splitUrl[1]}`;
      } else if (this.catImage !== "") return this.catImage;
      else return "";
    },

    billableDays() {
      if (this.taskRecord && this.taskRecord.billableDays) {
        return this.taskRecord.billableDays;
      } else return "?";
    },

    availableDays() {
      if (this.taskRecord && this.taskRecord.availableDays !== undefined) {
        return this.taskRecord.availableDays;
      } else return "?";
    }
  },

  created() {
    this.currentViewMode = this.viewModes[3];

    subscribeToRandomizedEmployee(this.selectedOffice, async ({ employee }) => {
      this.catImage = "";
      if (employee.email) {
        this.currentViewMode = this.viewModes[1];

        setTimeout(async () => {
          this.selectedEmployee = employee;
          const docId = this.currentWeekRecordId(employee.email);
          this.taskRecord = await getTaskRecord(docId);
          this.currentViewMode = this.viewModes[0];
          if (!employee.imageUrl) {
            let response = await fetch(
              "https://api.thecatapi.com/v1/images/search?limit=1&mime_types=&order=Random&size=large&page=0&category_ids=4&sub_id=demo-adefd4"
            );
            let data = await response.json();
            this.catImage = data[0].url;
          }
        }, 2000);
      } else if (employee.finished) {
        this.currentViewMode = this.viewModes[2];
      } else if (employee.finished === false) {
        this.currentViewMode = this.viewModes[3];
      }
    });
  }
};
</script>

<style scoped>
.hour-info {
  text-align: center;
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 1em auto auto;
}
.billable,
.available {
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.7);
}

.billable > div:first-child,
.available > div:first-child {
  font-weight: bold;
  font-size: 1.2rem;
}
.card-view {
  background-image: linear-gradient(#353a44, #242a32);
  flex: 1 1 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  padding: 4rem;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.name {
  font-size: 3rem;
  text-align: center;
}

.working-on {
  margin-top: 3rem;
  font-size: 2.5rem;
  text-align: center;
}

.working-on > h4 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #26c6da;
}

.image-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.working-image {
  max-width: 100%;
  max-height: 50vh;
  margin: auto;
}

.spinner {
  font-size: 80vh;
  animation: spinner 2s infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>
