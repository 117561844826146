<template>
  <ul class="list" :class="{ 'list--highlight': listHighlighted }">
    <board-list-item
      v-for="(item, index) in people"
      :key="index"
      :person="item"
      :selected="selectedPerson"
      :lunchRecords="lunchRecords"
      :isHistoryMode="isHistoryMode"
    />
  </ul>
</template>

<script>
import BoardListItem from "./BoardListItem";
export default {
  components: {
    BoardListItem
  },

  props: {
    people: {
      type: Array,
      required: true
    },
    listHighlighted: {
      type: Boolean,
      required: true
    },
    selectedPerson: {
      type: String,
      required: true
    },
    lunchRecords: {
      type: Array,
      required: true
    },
    isHistoryMode: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style>
.list--highlight .item__user {
  opacity: 0.5;
}

.list--highlight .item__tasks,
.list--highlight .item__billable,
.list--highlight .item__available {
  opacity: 0.2;
}

.list--highlight .item--highlight > * {
  opacity: 1;
}

.list--highlight .item--highlight .item__user,
.list--highlight .item--highlight .item__billable,
.list--highlight .item--highlight .item__available {
  color: #00f9f6;
}

.list--highlight .item--highlight::after {
  opacity: 1;
}

.list--highlight .item--highlight + .item .item__user {
  opacity: 1;
}

.list--highlight .item--highlight + .item + .item .item__user {
  opacity: 0.7;
}
</style>
