<template>
  <li class="item" :class="{ 'item--highlight': isSelected }">
    <div class="item__user">{{ person.firstName }}</div>
    <div class="item__tasks">{{ taskRecord.workingOn }}</div>
    <div class="list-right">
      <div
        class="item__lunch"
        :class="{ 'item__lunch--error': !hasCreatedLunchRecord }"
      ></div>
      <div class="item__billable">{{ taskRecord.billableDays }}</div>
      <div class="item__available">{{ taskRecord.availableDays }}</div>
    </div>
  </li>
</template>

<script>
import { subscribeTaskRecord } from "@/api";

export default {
  props: {
    person: {
      type: Object,
      required: true
    },
    selected: {
      type: String,
      required: true
    },
    lunchRecords: {
      type: Array,
      required: true
    },
    isHistoryMode: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      taskRecord: {
        workingOn: "",
        availableDays: "0",
        billableDays: "0"
      },
      unsubscribe: null
    };
  },

  computed: {
    isSelected() {
      return this.person.email === this.selected;
    },

    hasCreatedLunchRecord() {
      let found = this.lunchRecords.find(
        rec => rec.user === this.person.email.trim()
      );
      return !!found;
    }
  },

  created() {
    let docId;
    if (!this.isHistoryMode) {
      docId = this.currentWeekRecordId(this.person.email);
    } else {
      docId = this.historicRecordId({
        week: this.$route.params.week,
        year: this.$route.params.year,
        email: this.person.email
      });
    }
    this.unsubscribe = subscribeTaskRecord(docId, data => {
      this.taskRecord = data;
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style>
.item {
  font-size: 16px;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  border-top: 0.08rem solid rgba(255, 255, 255, 0.05);
  border-bottom: 0.08rem solid rgba(0, 0, 0, 0.3);
  transition: all 0.8s ease-in-out;
}
.item__user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  font-weight: 700;
  color: #7a8799;
  transition: all 0.8s ease-in-out;
}
.item__tasks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  padding-left: 2rem;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 300;
  transition: all 0.8s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}

.list-right {
  display: flex;
  flex: 0 0 230px;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.item__lunch,
.item__billable,
.item__available {
  flex: 1 1 50px;
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  color: #9295a6;
  transition: all 0.8s ease-in-out;
}

.item__lunch::before,
.item__billable::before,
.item__available::before {
  content: "\e906";
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 2;
  font-family: "icons";
  font-size: 1rem;
  line-height: 1;
  color: #9295a6;
  opacity: 0.6;
  font-weight: 300;
}
.item__available::before {
  content: "\e908";
}

.item__lunch {
  height: 25px;
}
.item__lunch::before {
  top: 4px;
  left: 50%;
  font-size: 1rem;
  opacity: 1;
  transform: translateX(-50%);
}
.item__lunch--done::before {
  color: #9295a6;
}
.item__lunch--error::before {
  color: #b66767;
}
.item__lunch::before {
  content: "\e911";
}

.item::after,
.item::before {
  content: "";
  position: absolute;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  background-image: linear-gradient(#00f9f6, #27d5fb);
  border-radius: 100px;
  overflow: hidden;
  box-shadow: 0 0 0.7rem rgba(0, 255, 231, 0.3);
  opacity: 0;
  transition: all 0.8s ease-in-out;
}
.item::before {
  background-image: none;
  background-color: #fdaa00;
  box-shadow: 0 0 0.7rem rgba(252, 170, 0, 0.3);
}
</style>
