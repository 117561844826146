<template>
  <div class="board-wrapper" v-if="!showCardView">
    <div class="board">
      <board-list
        :people="people"
        :listHighlighted="listHighlighted"
        :selectedPerson="selectedPerson"
        :lunchRecords="lunchRecords"
        :isHistoryMode="isHistoryMode"
      />
    </div>
    <stats
      :office="selectedOffice"
      :isHistoryMode="isHistoryMode"
      :taskRecords="taskRecords"
    />
  </div>
  <board-card-view
    v-else
    :people="people"
    :listHighlighted="listHighlighted"
    :selectedPerson="selectedPerson"
    :lunchRecords="lunchRecords"
  />
</template>

<script>
import {
  fetchPeople,
  subscribeSelectedPerson,
  subscribeLunchRecords,
  subscribeTaskRecords
} from "@/api";

import Stats from "./Stats";
import BoardCardView from "./BoardCardView";
import BoardList from "./BoardList";

export default {
  components: {
    BoardCardView,
    BoardList,
    Stats
  },

  props: {
    isHistoryMode: Boolean
  },

  data() {
    return {
      showCardView: false,
      people: [],
      loading: false,
      unsubscribeSelectedPerson: null,
      unsubscribeLunchRecords: null,
      selectedPerson: "",
      lunchRecords: [],
      taskRecords: [],
      unsubscribeTaskRecords: null
    };
  },
  computed: {
    selectedOffice() {
      return this.$route.params.office;
    },
    listHighlighted() {
      return this.selectedPerson !== "";
    },

    year() {
      return this.isHistoryMode
        ? parseInt(this.$route.params.week)
        : new Date().getFullYear();
    }
  },
  async created() {
    // Subscribe to selected person
    this.unsubscribeSelectedPerson = subscribeSelectedPerson(
      this.selectedOffice,
      value => (this.selectedPerson = value)
    );
    this.people = await fetchPeople(this.selectedOffice);

    // Subscribe to lunch records (so we can indicate which users have/haven't updated lunch)
    this.unsubscribeLunchRecords = subscribeLunchRecords(
      {
        office: this.selectedOffice,
        week: this.isHistoryMode
          ? parseInt(this.$route.params.week)
          : this.weekNumber,
        year: this.isHistoryMode ? parseInt(this.$route.params.year) : this.year
      },
      records => {
        this.lunchRecords = records;
      }
    );

    this.unsubscribeTaskRecords = subscribeTaskRecords(
      {
        office: this.selectedOffice,
        week: this.isHistoryMode
          ? parseInt(this.$route.params.week)
          : this.weekNumber,
        year: this.isHistoryMode ? parseInt(this.$route.params.year) : this.year
      },
      records => {
        this.taskRecords = records;
      }
    );
  },

  beforeDestroy() {
    this.unsubscribeSelectedPerson();
    this.unsubscribeLunchRecords();
    this.unsubscribeTaskRecords();
  }
};
</script>

<style>
.board-wrapper {
  display: flex;
  flex-flow: column;
}

.board {
  background-image: linear-gradient(#323a44, #242a32);
  flex: 1 1 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
}
</style>
