<template>
  <div class="panic">
    <img src="../assets/panic.gif" alt="Panic" class="panic__asset">
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.panic {
  overflow: hidden;
  overflow-y: hidden;
  height: 100vh;
}
.panic__asset {
  width: 100%;
  height: auto;
}
</style>
